import axios from 'axios';
import {API_BASE_URL} from 'configs/AppConfig';
import {notification} from 'antd';
import {getCookie, removeCookie, setJwtCookie} from '../services/CookieService'
import {AUTH_TOKEN, REFRESH_TOKEN, REMEMBER_ME} from "../constants/AuthConstant";
import IntlMessage from "../components/util-components/IntlMessage";
import {signOutSuccess} from "../store/slices/authSlice";
import store from "../store";
import AuthService from "../services/AuthService";

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000,
    validateStatus: null
})

const TOKEN_PAYLOAD_KEY = 'authorization'

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
    refreshSubscribers.push(cb);
};

const onTokenRefreshed = (token) => {
    refreshSubscribers.forEach(cb => cb(token));
    refreshSubscribers = [];
};

service.interceptors.request.use(async config => {
        if (!config.url?.includes('auth/refresh-token') && !config.url?.includes('/users/registration')) {
            let jwtToken = getCookie(AUTH_TOKEN) || null;
            if (jwtToken) {
                config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
            } else {
                const refreshToken = getCookie(REFRESH_TOKEN);
                if (refreshToken && !isRefreshing) {
                    isRefreshing = true;
                    const response = await AuthService.refreshToken({
                        "refresh_token": refreshToken
                    });

                    if (response) {
                        setJwtCookie(response, getCookie(REMEMBER_ME));
                        jwtToken = response.token;
                        onTokenRefreshed(jwtToken);
                    }

                    isRefreshing = false;
                } else if (refreshToken && isRefreshing) {
                    return new Promise((resolve) => {
                        subscribeTokenRefresh((newToken) => {
                            config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${newToken}`;
                            resolve(config);
                        });
                    });
                }
            }

            config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
        }
        return config;
    },
    error => {
        notification.error({
            message: 'Error'
        })
        Promise.reject(error)
    }
)

// API respone interceptor
service.interceptors.response.use(
    (response) => {
        let notificationParam = {
            message: ''
        };

        if (response.data.code === 401) {
            notificationParam.message = <IntlMessage id={'auth.check.authFail'}/>;
            notificationParam.description = response.data.message;
            removeCookie(AUTH_TOKEN);
            removeCookie(REFRESH_TOKEN);
            localStorage.removeItem('userData');

            store.dispatch(signOutSuccess());
        }

        if (response.data.code === 404 || response.status === 404) {
            notificationParam.message = response.data.detail;
        }

        if (response.data.code === 403 || response.status === 403) {
            window.location.href = '/app/error/accessDenied';
        }

        if (response.data.code === 422 || response.status === 422) {
            if (response.data.detail && response.data.detail.includes(":")) {
                const parts = response.data.detail.split(":");
                notificationParam.message = parts.slice(1).join(":").trim();
            } else {
                notificationParam.message = response.data.detail || 'Server error 422';
            }
        }

        if (response.data.code === 500 || response.status === 500) {
            if (response.data.detail && response.data.detail.includes(":")) {
                const parts = response.data.detail.split(":");
                notificationParam.message = parts.slice(1).join(":").trim();
            } else {
                notificationParam.message = response.data.detail || 'Server error 500';
            }
        }

        if (response.data.code === 508 || response.status === 508) {
            notificationParam.message = response.data.detail;
        }

        if (notificationParam.message !== '') {
            notification.error(notificationParam);
        }

        return response.data;
    },
    (error) => {
        let notificationParam = {
            message: 'Error',
            description: error.message
        };

        if (error.response) {
            switch (error.response.status) {
                case 401:
                    notificationParam.message = 'Unauthorized';
                    removeCookie(AUTH_TOKEN);
                    removeCookie(REFRESH_TOKEN);
                    localStorage.removeItem('userData');
                    store.dispatch(signOutSuccess());
                    break;
                case 403:
                    notificationParam.message = 'Forbidden';
                    window.location.href = '/app/error/accessDenied';
                    break;
                case 404:
                    notificationParam.message = 'Not Found';
                    break;
                case 500:
                    notificationParam.message = 'Internal Server Error';
                    break;
                case 508:
                    notificationParam.message = 'Loop Detected';
                    break;
                default:
                    notificationParam.message = `Error ${error.response.status}`;
                    notificationParam.description = error.response.data.detail || error.response.statusText;
            }
        } else {
            notificationParam.message = 'Network Error';
            notificationParam.description = error.message;
            // window.location.href = '/app/error/error';
        }

        notification.error(notificationParam);
        return Promise.reject(error);
    }
);

export default service