import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ThemeSwitcherProvider} from 'react-css-theme-switcher';
import store from './store';
import Layouts from './layouts';
import {REACT_APP_GOOGLE_MAPS_API_KEY, THEME_CONFIG} from './configs/AppConfig';
import './lang';
import ErrorBoundary from "./views/app-views/components/ErrorBoundaryComponent";
import {LoadScript} from "@react-google-maps/api";
import {firstCheck} from "./serviceHandler/user/UserServiceHandler";
import LoadingComponent from "./views/app-views/components/LoadingComponent";

const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {

    useEffect(() => {
        firstCheck();
    }, []);

    return (
        <>
            <Provider store={store}>
                <BrowserRouter future={{
                    v7_relativeSplatPath: true,
                    v7_startTransition: true
                }}>
                    <ErrorBoundary>
                        <LoadScript
                            googleMapsApiKey={`${REACT_APP_GOOGLE_MAPS_API_KEY}&loading=async`}
                            loadingElement={<LoadingComponent/>}
                            libraries={["places"]}
                        >
                            <ThemeSwitcherProvider
                                themeMap={themes}
                                defaultTheme={THEME_CONFIG.currentTheme}
                                insertionPoint="styles-insertion-point"
                            >
                                <Layouts/>
                            </ThemeSwitcherProvider>
                        </LoadScript>
                    </ErrorBoundary>
                </BrowserRouter>
            </Provider>
        </>
    );
}

export default App;
