import fetch from 'auth/FetchInterceptor'

const UserService = {}

UserService.getDataForCurrentUser = function () {
	return fetch({
		url: 'users/me',
		method: 'get'
	});
}

UserService.rolesByUser = function (key) {
	return fetch({
		url: 'roles/' + key,
		method: 'get'
	});
}

UserService.userPrograms = function (currentUrl) {
	return fetch({
		url: 'menu-items?currentUrl=' + currentUrl,
		method: 'get'
	});
}

UserService.checkRegistraionToken = function (token) {
	return fetch({
		url: 'registration-token/' + token,
		method: 'put'
	});
}

UserService.userSearchAdmin = function (country, fullName, username, userRole, address, page) {
	return fetch({
		url: 'search/users?page=' + page + '&address='+ address + '&country=' + country + '&fullName=' + fullName + '&userRole=' + userRole + '&username=' + username,
		method: 'get'
	});
}

UserService.getUserById = function (id) {
	return fetch({
		url: 'users/' + id,
		method: 'get'
	});
}


UserService.createNewAdmin = function (data) {
	return fetch({
		url: 'new-user-account',
		method: 'post',
		data: data
	});
}

UserService.update = function (data, id) {
	return fetch({
		url: 'user-update/' + id,
		method: 'put',
		data: data
	});
}

UserService.getAll = function (page) {
	return fetch({
		url: 'users?page=' + page,
		method: 'get'
	});
}

UserService.delete = function (key) {
	return fetch({
		url: 'users/' + key,
		method: 'delete'
	});
}

export default UserService;