import UserService from 'services/user/UserService';
import {getCookie, getUser, removeCookie, setUser} from '../../services/CookieService'
import {AUTH_TOKEN, REFRESH_TOKEN} from "../../constants/AuthConstant";
import store from "../../store";
import {signOutSuccess} from "../../store/slices/authSlice";

const firstCheck = async () => {
    const user = getUser();
    const token = getCookie(AUTH_TOKEN)
    if ((token !== undefined && token !== null) && (user === undefined || user === null)) {
        await saveCurrentUserData()
    }
}

const saveCurrentUserData = async () => {
    const response = await UserService.getDataForCurrentUser()
    setUser(response);
    return response;
}

const getUserById = async (id) => {
    if (id) {
        return UserService.getUserById(id);
    } else {
        if (id === 0) {
            removeCookie(AUTH_TOKEN);
            removeCookie(REFRESH_TOKEN);
            localStorage.removeItem('userData');
            store.dispatch(signOutSuccess())
        }
    }
}

const createNewAdmin = async (data, image) => {
    try {
        return await UserService.createNewAdmin({
            email: data.email,
            password: data.password,
            repeatPassword: data.repeatPassword,
            roles: data.associationRoles,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            address: data.address,
            city: data.city,
            phoneCodeNumber: data.phoneCodeNumber,
            country: data.country,
            image: image
        })
    } catch (err) {
        return false;
    }
}

const update = async (data, image, id, firstData) => {
    let associationRoles = data.associationRoles || firstData.associationRoles;

    if (firstData.roles?.includes('ROLE_ADMIN')) associationRoles = [];

    try {
        const finalData = {
            email: data.email || firstData.email,
            password: data.password || firstData.password,
            repeatPassword: data.repeatPassword || firstData.repeatPassword,
            roles: associationRoles,
            firstName: data.firstName || firstData.firstName,
            lastName: data.lastName || firstData.lastName,
            phoneNumber: data.phoneNumber || firstData.phoneNumber,
            address: data.address || firstData.address,
            phoneCodeNumber: data.phoneCodeNumber || firstData.phoneCodeNumber,
            city: data.city || firstData.city,
            country: data.country || firstData.country,
            image: !image ? '' : image ,
        };
        return await UserService.update(finalData, id);
    } catch (err) {
        return false;
    }
};

const getAllActive = async (page) => {
    return UserService.getAll(page);
}

const deleteUser = async (key) => {
    return UserService.delete(key);
}

const rolesByUser = async (key) => {
    return UserService.rolesByUser(key);
}

const userPrograms = async () => {
    const currentPath = `${window.location.pathname}${window.location.search}`;
    return UserService.userPrograms(currentPath);
}

const userSearchAdmin = async (search, page) => {
    return UserService.userSearchAdmin(
        search.country,
        search.fullName,
        search.username,
        search.userRole,
        search.address,
        page
    );
}

export {
    saveCurrentUserData,
    getUserById,
    getAllActive,
    update,
    deleteUser,
    rolesByUser,
    userSearchAdmin,
    createNewAdmin,
    firstCheck,
    userPrograms
};